import React from "react"
import { Link } from "gatsby"
import { Tag } from "antd"

const Summary = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug
  const description =
    post.frontmatter.description.length > 72
      ? post.frontmatter.description.substr(0, 72) + "..."
      : post.frontmatter.description
  return (
    <article
      className="post-list-item"
      itemScope
      itemType="http://schema.org/Article"
    >
      <div
        className="cover"
        style={{
          backgroundImage: `url(${post.frontmatter.image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Link to={post.fields.slug} itemProp="url">
          <div className="dummy" />
        </Link>
      </div>
      <div className="summary">
        <header>
          <Link to={post.fields.slug} itemProp="url">
            <h2>
              <span itemProp="headline">{title}</span>{" "}
              {post.frontmatter.status && (
                <Tag color="purple">{post.frontmatter.status}</Tag>
              )}
            </h2>
          </Link>
        </header>
        <section>
          <small>
            <Link to={post.fields.slug} itemProp="url">
              <p
                dangerouslySetInnerHTML={{
                  __html: description || post.excerpt,
                }}
                itemProp="description"
              />
            </Link>
          </small>
        </section>
      </div>
    </article>
  )
}

export default Summary
